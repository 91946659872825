import React, { useState, useEffect } from "react";
import "./LoginStyles.scss";
import logo from "../../../assets/reserveLg.png";
import AuthInputComponent from "../../../components/inputcomponent/AuthInputComponent";
import Button from "../../../components/button/Button";
import { userLogin } from "../../../../infra/apis/auth/requests/Auth";
import { useNavigate } from "react-router-dom";
import {
  getFloorManagementDetails,
  getOrganizationDetails,
} from "../../../../infra/apis/bookings/requests/Booking";
import { useDispatch } from "react-redux";
import {
  setLocationId,
  setOrganizationalDetails,
  setFloorLayoutData,
  setDefaultFloorLayoutData,
} from "../../../../application/reducer/bookingSlice";
import moment from "moment";
import Modal from "../../../components/ReservationComponents/errorModal/Modal";
import warning from "../../../assets/warning.png";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
    email: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [invalidError, setInvalidError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails !== "" && userDetails !== null) {
      navigate("/v1/booking/list-view");
    } else {
      localStorage.clear();
    }
  }, []);

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      password: "",
      email: "",
    };

    if (password.trim() === "") {
      newErrors.password = "*required";
      valid = false;
    }
    if (email.trim() === "") {
      newErrors.email = "*required";
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = "*Invalid email format";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      userLogin(email, password).then((success) => {
        if (success === 200) {
          localStorage.setItem("drawerOpen", JSON.stringify(false));
          let locationId = localStorage.getItem("wrlId") || "";
          setLocationId(locationId);
          getFloorManagementDetails().then((response: any) => {
            if (response.length === 0) {
              navigate("/v1/booking/floor-layout");
            } else {
              dispatch(setFloorLayoutData(response));
              dispatch(setDefaultFloorLayoutData(response));
              getOrganizationDetails(locationId || "").then(
                (orgnizationDetails) => {
                  dispatch(setOrganizationalDetails(orgnizationDetails));
                }
              );
              navigate("/v1/booking/list-view");
            }
          });
        } else if (success === 404) {
          setModalOpen(true);
          setEmail("");
          setPassword("");
          setIsLoading(false);
        } else {
          setInvalidError(true);
          setPassword("");
          setIsLoading(false);
        }
      });
    }
  };
  const closeModal = () => setModalOpen(false);
  return (
    <div className='LoginStyles'>
      {window.screen.width > 900 ? (
        <>
          <div className='left-login-container'>
            <div className='logo-container'>
              <img alt='' src={logo} />
            </div>
            <div className='text-container '>
              The multipurpose tool you need to succeed in Reservation
            </div>
          </div>
          <div className='right-login-container'>
            <div className='form-container'>
              <div className='header'>Sign In</div>
              <div className='form'>
                <AuthInputComponent
                  label='Email Address'
                  value={email}
                  onChange={(value) => {
                    handleChange("email");
                    setEmail(value);
                    setInvalidError(false);
                  }}
                  type='email'
                  error={errors.email}
                />
                <AuthInputComponent
                  label='Password'
                  value={password}
                  onChange={(value) => {
                    handleChange("password");
                    setPassword(value);
                    setInvalidError(false);
                  }}
                  type='password'
                  error={errors.password}
                />
                {invalidError && (
                  <div className='error'>
                    <img alt='' src={warning} />
                    Invalid email address or password
                  </div>
                )}
                <div style={{ marginTop: "1.5rem" }}>
                  <Button
                    text='Sign In'
                    auth
                    onClick={handleSubmit}
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={isModalOpen} onClose={closeModal} />
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
    </div>
  );
};
export default Login;
