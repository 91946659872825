import React, { useState, useEffect } from "react";
import "./ReservationStyles.scss";
import SideBar from "../../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../application/store";
import {
  getBookingDetails,
  getShiftDetails,
} from "../../../../infra/apis/bookings/requests/Booking";
import {
  setBookingDetails,
  setIslocationSwitching,
  setShiftDetails,
} from "../../../../application/reducer/bookingSlice";
import { useNavigate } from "react-router-dom";
import Drawer from "../../../components/ReservationComponents/drawer/Drawer";
import ReservationListViewComponent from "./ReservationListViewComponent";
import FloorDetails from "./floorDetails/FloorDetails";
import TabelDetails from "./tableDetails/TableDetails";
import moment from "moment";
import SuccessModal from "../../../components/ReservationComponents/errorModal/SuccessModal";
import { generateUniqueDates } from "../../../../application/utils";

const ReservationListView: React.FC = () => {
  const [value, setValue] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<any>(
    localStorage.getItem("selectedDate")
      ? JSON.parse(
          localStorage.getItem("selectedDate") || new Date().toDateString()
        )
      : new Date()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<string>("All Shifts");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(
    localStorage.getItem("drawerOpen")
      ? JSON.parse(localStorage.getItem("drawerOpen") || "false")
      : false
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTable, setSelectedTable] = useState<any>(null);
  const [selectedTables, setSelectedTables] = useState<boolean>(false);
  const [bookingTime, setBookingTime] = useState<any>(
    selectedTable
      ? selectedTable.BookingDetails?.BookingTime
      : moment(new Date()).format("HH:mm")
  );
  const [date, setDate] = useState<any>(
    selectedTable
      ? new Date(selectedTable.BookingDetails.BookingDate)
      : new Date()
  );
  const [guest, setGuest] = useState<number>(
    selectedTable ? selectedTable.BookingDetails?.NumberOfPeople : 0
  );
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
  const [width, setWidth] = useState<any>(992);

  const shiftData = useSelector(
    (state: RootState) => state.booking.shiftDetails
  );
  const bookingDetails = useSelector(
    (state: RootState) => state.booking.bookingDetails
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const islocationSwitching = useSelector(
    (state: RootState) => state.booking.islocationSwitching
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let upcomingFilter = ["late", "not confirmed", "confirmed", "no answer"];

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.screen.width);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    } else {
      getData();
    }
  }, [locationId]);
  useEffect(() => {
    getData();
    setSelectedFilter("");
  }, [selectedDate, locationId]);
  const getData = () => {
    let LocId = locationId === "" ? localStorage.getItem("wrlId") : locationId;
    if (LocId) {
      setLoading(true);
      getShiftDetails(LocId).then((shiftData) => {
        dispatch(setShiftDetails(shiftData));
        getBookingDetails(selectedDate).then((bookingData) => {
          dispatch(setBookingDetails(bookingData));
          setLoading(false);
          dispatch(setIslocationSwitching(false));
        });
      });
    } else {
      window.location.reload();
    }
  };
  const filterByName = (bookings: any) => {
    if (value === "") {
      return bookings;
    } else {
      return bookings.filter(
        (item: any) =>
          item.CustomerDetails.FirstName.toLowerCase().includes(
            value.toLowerCase()
          ) ||
          item.CustomerDetails.LastName.toLowerCase().includes(
            value.toLowerCase()
          )
      );
    }
  };
  const getUnsortedShifts = () => {
    if (!shiftData) {
      const data = bookingDetails || [];
      return data.length > 0 ? [{ Name: "Out of Shift", Bookings: data }] : [];
    } else {
      const data = bookingDetails || [];
      const shiftIDs = data
        .map((booking: any) => booking.BookingDetails.Shift?.Id)
        .filter((value: string) => value);
      let outOfShiftBookings: any[] = [];
      const formatted = moment(selectedDate).format("M/D/YYYY");

      const shiftsWithBookings = shiftData
        .map((shift: any) => {
          if (shift.Type === "RECURRING") {
            let active = shift.ShiftActiveDay.some(
              (day: any) =>
                day.Day === moment(selectedDate).format("dddd").toUpperCase()
            );

            const associatedBookings = data.filter(
              (booking: any) =>
                booking.BookingDetails.Shift?.Id === shift.ShiftManagementID
            );
            if (active && shift.IsActive) {
              return {
                Name: shift.Name,
                Bookings: associatedBookings,
              };
            } else if (associatedBookings.length > 0) {
              outOfShiftBookings =
                outOfShiftBookings.concat(associatedBookings);
            }
          }
          if (shift.Type === "ONETIME") {
            const onetime = [shift.TimeSchedule];
            const isActive = generateUniqueDates(onetime).some(
              (shiftDay: any) => {
                return shiftDay === formatted;
              }
            );
            const associatedBookings = data.filter(
              (booking: any) =>
                booking.BookingDetails.Shift?.Id === shift.ShiftManagementID
            );
            if (isActive) {
              return {
                Name: shift.Name,
                Bookings: associatedBookings,
              };
            }
          }

          return null;
        })
        .filter((shift: any) => shift !== null);
      const emptyShifts = data.filter(
        (booking: any) =>
          !booking.BookingDetails.Shift?.Id ||
          !shiftIDs.includes(booking.BookingDetails.Shift?.Id)
      );

      if (emptyShifts.length > 0) {
        outOfShiftBookings = outOfShiftBookings.concat(emptyShifts);
      }
      if (outOfShiftBookings.length > 0) {
        shiftsWithBookings.push({
          Name: "Out of Shift",
          Bookings: outOfShiftBookings,
        });
      }

      return shiftsWithBookings;
    }
  };

  const getShifts = () => {
    return getUnsortedShifts().filter((shift: any) => shift !== undefined);
  };
  const getshiftsWithBookings = () => {
    if (selectedShift === "All Shifts") {
      return getShifts();
    } else {
      return getShifts().filter((shift: any) => shift?.Name === selectedShift);
    }
  };
  const getSeatedBookings = (booking: any) => {
    if (selectedFilter === "") {
      return filterByName(booking);
    } else if (selectedFilter.toLowerCase() !== "seated") {
      return filterByName(booking).filter((bookingData: any) =>
        upcomingFilter.includes(bookingData.Status.toLowerCase())
      );
    } else {
      return filterByName(booking).filter(
        (bookingData: any) => bookingData.Status.toLowerCase() === "seated"
      );
    }
  };
  return (
    <div className='Reservation'>
      {islocationSwitching ? (
        <div className='empty'>
          <div className='loader' />
        </div>
      ) : width > 900 ? (
        <>
          {isDrawerOpen ? (
            <Drawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedMenu={"LIST"}
            />
          ) : (
            <SideBar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selected={"LIST"}
            />
          )}
          <div
            className='reservation-content'
            style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
          >
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='List View'
              details
              isOpenDrawer={isDrawerOpen}
            />
            {loading ? (
              <div className='loader' />
            ) : (
              <ReservationListViewComponent
                value={value}
                setValue={setValue}
                getshiftsWithBookings={getshiftsWithBookings}
                setSelectedShift={setSelectedShift}
                getUnsortedShifts={getShifts}
                selectedShift={selectedShift}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                getSeatedBookings={getSeatedBookings}
                setSelectedTable={setSelectedTable}
                setIsTableView={setIsModalOpen}
                isOpenDrawer={isDrawerOpen}
                selectedDate={selectedDate}
              />
            )}
          </div>
          {isModalOpen && (
            <FloorDetails
              isModalOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setSelectedTable(null);
              }}
              tableData={selectedTable}
              setSelectedTables={setSelectedTables}
              setIsModalOpen={setIsModalOpen}
              date={date}
              setDate={setDate}
              bookingTime={bookingTime}
              setBookingTime={setBookingTime}
              setGuest={setGuest}
              guest={guest}
              setSelectedTable={setSelectedTable}
              setIsUpdateSuccess={setIsUpdateSuccess}
            />
          )}
          {selectedTables && (
            <TabelDetails
              isModalOpen={selectedTables}
              onClose={() => {
                setIsModalOpen(true);
                setSelectedTables(false);
              }}
              tableData={selectedTable}
              setSelectedTable={setSelectedTable}
              date={date}
              bookingTime={bookingTime}
              guest={guest}
            />
          )}
          <SuccessModal
            isOpen={isUpdateSuccess}
            onClose={() => {
              window.location.reload();

              setIsUpdateSuccess(false);
            }}
            onOk={() => {
              setIsUpdateSuccess(false);
              window.location.reload();
            }}
            header='Successfully Updated!'
            content='Successfully, reservation details are updated.'
          />
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
    </div>
  );
};
export default ReservationListView;
