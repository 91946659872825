import React, { useState } from "react";
import { format } from "date-fns";
import "./DatePickerInputStyles.scss";
import arrow from "../../../assets/down-white.png";
import DatePickerComponet from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface DatePickerInputProps {
  selectedDate: any;
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
}
const DatePickerInput: React.FC<DatePickerInputProps> = ({
  selectedDate,
  setSelectedDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const formatDate = (date: Date): string => {
    return format(date, "EEE, MMM d");
  };
  return (
    <div className='DatePicker-Input'>
      <div className='date'>
        {isOpen ? (
          <div>
            <DatePickerComponet
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                setIsOpen(false);
              }}
              dateFormat={"dd LLLL YYYY"}
              open={isOpen}
              onClickOutside={() => setIsOpen(false)}
              minDate={new Date()}
            />
          </div>
        ) : (
          <div />
        )}
        <div className='date-container' onClick={() => setIsOpen(!isOpen)}>
          {formatDate(selectedDate)}
        </div>
        <img alt='' src={arrow} className='arrow' />
      </div>
    </div>
  );
};

export default DatePickerInput;
