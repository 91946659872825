import React from "react";
import "./StepsComponentStyles.scss";
interface StepsComponentProps {
  currentStep: number;
}
const StepsComponent: React.FC<StepsComponentProps> = ({ currentStep }) => {
  const steps: string[] = ["Reserve", "Payment"];
  return (
    <div className='step-container'>
      {steps.map((step, index: number) => (
        <>
          <div key={index} className='step'>
            <div
              className={`step-circle ${index <= currentStep ? "active" : ""}`}
            >
              {index + 1}
            </div>
            <div
              className={`step-name ${index <= currentStep ? "active" : ""}`}
            >
              {step}
            </div>
          </div>
          {index === 0 && (
            <div
              className={`line ${currentStep === index + 1 ? "active" : ""}`}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default StepsComponent;
