const {
  NODE_ENV,
  REACT_APP_TOKENSERVICE_URL,
  REACT_APP_RESERVATION_BASEURL,
  REACT_APP_USERSERVICE_URL,
  REACT_APP_ORAGNIZATION_BASEURL,
  REACT_APP_ENV,
  REACT_APP_PAYMENT_BASE_URL,
  REACT_APP_PAYMENT_CLIENT_KEY,
  REACT_APP_PAYMENT_CLIENT_ENVIRONMENT,
} = process.env;

const globalValues = {
  baseURLS: {
    REACT_APP_TOKENSERVICE_URL: REACT_APP_TOKENSERVICE_URL,
    REACT_APP_USERSERVICE_URL: REACT_APP_USERSERVICE_URL,
    REACT_APP_RESERVATION_BASEURL: REACT_APP_RESERVATION_BASEURL,
    REACT_APP_ORAGNIZATION_BASEURL: REACT_APP_ORAGNIZATION_BASEURL,
    REACT_APP_PAYMENT_BASE_URL: REACT_APP_PAYMENT_BASE_URL,
    REACT_APP_PAYMENT_CLIENT_KEY: REACT_APP_PAYMENT_CLIENT_KEY,
    REACT_APP_PAYMENT_CLIENT_ENVIRONMENT: REACT_APP_PAYMENT_CLIENT_ENVIRONMENT,
  },

  environment: {
    CURRENT_ENVIRONMENT: NODE_ENV,
    IS_DEVELOPMENT: NODE_ENV === "development",
    ENV: REACT_APP_ENV,
  },
};
export default globalValues;
