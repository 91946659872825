import React, { useState, useEffect } from "react";
import "./BookingStyles.scss";
import LogoContainer from "../../components/logocontainer/LogoContainer";
import Button from "../../components/button/Button";
import BaseHeader from "../../components/baseheader/BaseHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../application/store";
import {
  setEmail,
  setGuest,
  setPhoneNumber,
  setRequest,
  setDate,
  setTime,
  setPrePaymentData,
  setFirstName,
  setLastName,
  setTableDuration,
} from "../../../application/reducer/bookingSlice";
import moment from "moment";
import {
  fethPaymentDetails,
  getPrePaymentDetails,
  makeReservation,
  sendPaymentDetails,
  storeTempBooking,
  updateTempBooking,
} from "../../../infra/apis/bookings/requests/Booking";
import ErrorModal from "../../components/ReservationComponents/errorModal/ErrorModal";
import StepsComponent from "../../components/steps/StepsComponent";
import BookingPaymentContainer from "./BookingPaymentContainer";
import { v4 as uuidv4 } from "uuid";
import PaymentFailed from "../../components/ReservationComponents/errorModal/PaymentFailed";

const BookingPayment: React.FC = () => {
  const dispatch = useDispatch();
  const [isError, setIsError] = useState<boolean>(false);
  const [payment, setPayment] = useState<any>(null);
  const [isPaymentError, setIsPaymentError] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [isBookingErrorLoading, setIsBookingErrorLoading] =
    useState<boolean>(false);

  const guest = useSelector((state: RootState) => state.booking.guest);
  const date = useSelector((state: RootState) => state.booking.date);
  const time = useSelector((state: RootState) => state.booking.time);
  const firstName = useSelector((state: RootState) => state.booking.firstName);
  const lastName = useSelector((state: RootState) => state.booking.lastName);
  const email = useSelector((state: RootState) => state.booking.email);
  const phoneNumber = useSelector(
    (state: RootState) => state.booking.phoneNumber
  );
  const request = useSelector((state: RootState) => state.booking.request);
  const tableDuration = useSelector(
    (state: RootState) => state.booking.tableDuration
  );
  const prePaymentData = useSelector(
    (state: RootState) => state.booking.prePaymentData
  );
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const redirectResult = params.get("redirectResult");
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(
    redirectResult ? true : false
  );
  useEffect(() => {
    if (!localStorage.getItem("BSID")) {
      localStorage.setItem("BSID", uuidv4());
    }
    if (!localStorage.getItem("REID")) {
      localStorage.setItem("REID", uuidv4());
    }
  }, []);
  useEffect(() => {
    let id = localStorage.getItem("wrlId");
    if (id === null) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (!redirectResult) {
      handleTempStoring();
    }
  }, []);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      setLoading(true);
      let shiftId = localStorage.getItem("bss_oid");
      if (shiftId) {
        try {
          const shiftDet: any = await getPaymentDetails(shiftId);
          if (shiftDet.length > 0) {
            let paymentData = shiftDet.filter((shift: any) =>
              shift.Shift.filter(
                (value: any) => value.IsEnabled && value.Id === shiftId
              )
            )[0];
            if (paymentData) {
              dispatch(setPrePaymentData(paymentData));
              if (
                paymentData &&
                localStorage.getItem("wrlId") &&
                localStorage.getItem("bss_oid")
              ) {
                let data = {
                  CloudLocationID: localStorage.getItem("wrlId"),
                  ShiftID: localStorage.getItem("bss_oid"),
                  PrePaymentRuleID: paymentData.PaymentRuleID,
                  Covers: guest,
                  ReservationDate: moment(date).format(
                    "YYYY-MM-DDTHH:mm:ss[Z]"
                  ),
                  Time: time,
                };
                let value = await fetchPrePaymentDetails(data);
                if (value) {
                  setPayment(value);
                } else {
                  setPayment(null);
                }
              }
            } else {
              dispatch(setPrePaymentData(null));
            }
          }
        } catch (error) {
          console.error("Error fetching payment details:", error);
        }
      } else {
      }
      setLoading(false);
    };
    fetchPaymentDetails();
  }, [time, date]);
  const paymentData: any = {
    total: payment?.Payment?.Amount?.Amount,
    cloudLocationID: localStorage.getItem("wrlId"),
    orderID: "",
    email: email,
    name: `${firstName} ${lastName}`,
    phoneNumber: phoneNumber,
    userID: "",
    ID: localStorage.getItem("REID"),
    guest: guest,
    date: moment(date).format("MMMM DD, YYYY"),
    shopperReference: localStorage.getItem("BSID"),
    reference: `Online-${localStorage.getItem(
      "wrlId"
    )}-Ecom-${localStorage.getItem("REID")}`,
  };
  useEffect(() => {
    if (redirectResult && payment && paymentData) {
      if (!isError && !isPaymentError) {
        sendPaymentDetailsRedirect();
      }
    }
  }, [paymentData, payment, redirectResult]);

  const sendPaymentDetailsRedirect = () => {
    const paymentDetailsRequest = {
      cloudLocationID: paymentData?.cloudLocationID,
      reference: paymentData?.reference,
      siteTransactionRef: paymentData?.ID,
      store: `Ecommerce_${paymentData?.cloudLocationID}`,
      countryCode: "GB",
      paymentDetailInput: {
        redirectResult: redirectResult,
      },
      shopperReference: paymentData.shopperReference,
    };
    setIsPaymentLoading(true);
    sendPaymentDetails(paymentDetailsRequest, payment?.PaymentToken).then(
      (response: any) => {
        if (response.resultCode === "Authorised") {
          handleSubmit(response);
        }
      }
    );
  };

  // useEffect(() => {
  //   const fetchPreValuePaymentDetails = async () => {
  //     if (
  //       prePaymentData &&
  //       localStorage.getItem("wrlId") &&
  //       localStorage.getItem("bss_oid")
  //     ) {
  //       let data = {
  //         CloudLocationID: localStorage.getItem("wrlId"),
  //         ShiftID: localStorage.getItem("bss_oid"),
  //         PrePaymentRuleID: prePaymentData.PaymentRuleID,
  //         Covers: guest,
  //         ReservationDate: moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
  //         Time: time,
  //       };
  //       let value = await fetchPrePaymentDetails(data);
  //       if (value) {
  //         setPayment(value);
  //       } else {
  //         setPayment(null);
  //       }
  //     }
  //   };
  //   fetchPreValuePaymentDetails();
  // }, [prePaymentData]);
  const fetchPrePaymentDetails = async (data: any) => {
    try {
      const res = await fethPaymentDetails(data);
      return res || null;
    } catch (error) {
      return null;
    }
  };
  const getPaymentDetails = async (id: string) => {
    try {
      const res = await getPrePaymentDetails(id);
      return res || [];
    } catch (error) {
      return [];
    }
  };

  const handleSubmit = (paymentResponse: any) => {
    let reserve = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber
        .replace(" ", "")
        .replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3"),
      email: email,
      date: moment(date).format("YYYY-MM-DD"),
      guest: guest,
      time: time,
      request: request,
      duration: tableDuration,
      ID: localStorage.getItem("REID"),
      PaymentDetails: {
        RefID: paymentResponse?.pspReference,
        Amount: payment?.Payment?.Amount?.Amount,
        PaymentMethod: "CARD",
        PaymentDate: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        PaymentStatus: "COMPLETED",
        Currency: {
          CurrencyCode: "GB",
          Symbol: "£",
        },
        TransactionID: paymentResponse?.merchantReference,
        Status: "Completed",
      },
    };
    makeReservation(reserve)
      .then((reserveObj: any) => {
        setIsPaymentLoading(false);
        if (reserveObj !== false) {
          clear();
          navigate("/booking/confirmation", { state: reserveObj });
        } else {
          setIsError(true);
        }
      })
      .catch(() => {
        setIsPaymentLoading(false);
        setIsError(true);
      });
  };
  const handleTempStoring = () => {
    let reserve = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber
        .replace(" ", "")
        .replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3"),
      email: email,
      date: moment(date).format("YYYY-MM-DD"),
      guest: guest,
      time: time,
      request: request,
      duration: tableDuration,
      ID: localStorage.getItem("REID"),
    };
    storeTempBooking(reserve)
      .then((reserveObj: any) => {
        if (reserveObj) {
          console.log("successfully stored");
        }
      })
      .catch(() => {
        console.log("Failed storing booking");
      });
  };
  const clear = () => {
    dispatch(setFirstName(""));
    dispatch(setLastName(""));
    dispatch(setGuest(0));
    dispatch(setDate(new Date().toDateString()));
    dispatch(setTime(""));
    dispatch(setEmail(""));
    dispatch(setPhoneNumber(""));
    dispatch(setRequest(""));
    dispatch(setTableDuration(0));
    localStorage.removeItem("BSID");
    localStorage.removeItem("REID");
    localStorage.removeItem("bss_oid");
  };
  const handleBookingFailed = async () => {
    setIsBookingErrorLoading(true);
    let id: string = localStorage.getItem("REID") || "";
    await updateTempBooking(id).then((res) => {
      clear();
      setIsError(false);
      let id = localStorage.getItem("wrlId");
      if (id !== null) {
        navigate(`/booking/slots/${id}`);
        setIsBookingErrorLoading(false);
      }
    });
  };

  return (
    <div className='page2'>
      {isPaymentLoading && (
        <div className='payment-loader'>
          <div className='loading-icon ' />
          Your payment is being processed. Please wait.
        </div>
      )}
      <div className='border' />
      <div className='BookingPageTwo'>
        <div className='left-container'>
          {/* <div className='logo-witmeg'>
            <img alt='' src={witmeg} />
          </div> */}
          <LogoContainer />
        </div>
        <div className='right-container'>
          <div className='form-container'>
            <BaseHeader />
            {loading ? (
              <div className='loader' />
            ) : (
              <>
                <div className='form'>
                  <StepsComponent currentStep={1} />
                  {prePaymentData?.Terms?.length > 0 && (
                    <>
                      <div className='header'>Payment Terms</div>

                      <ul className='terms-list'>
                        {prePaymentData?.Terms.map(
                          (rules: string, index: number) => {
                            return <li key={index}>{rules}</li>;
                          }
                        )}
                      </ul>
                    </>
                  )}

                  <div className='payment-container'>
                    <div className='payment-container-left'>
                      <div className='header-payment '>Payment</div>
                      <BookingPaymentContainer
                        paymentMethods={payment?.PaymentMethods || {}}
                        paymentData={paymentData}
                        paymentToken={payment?.PaymentToken}
                        makeReservation={handleSubmit}
                        setIsPaymentError={setIsPaymentError}
                        setIsPaymentLoading={setIsPaymentLoading}
                      />

                      <div className='back-btn-cont'>
                        <Button
                          text='Back'
                          onClick={() => {
                            dispatch(setPrePaymentData(null));
                            navigate("/booking/customer-details");
                          }}
                          borderAuth
                        />
                      </div>
                    </div>
                    <div className='payment-container-right '>
                      <div className='header-payment '>
                        Reservation Pre-Booking
                      </div>
                      <div className='booking-det'>
                        <div className='booking-det-item'>
                          <div className='item-head'>Date:</div>
                          <div>{date}</div>
                        </div>
                        <div className='booking-det-item'>
                          <div className='item-head'>Time:</div>
                          <div>{time}</div>
                        </div>
                        <div className='booking-det-item'>
                          <div className='item-head'>Number of Covers:</div>
                          <div>{guest}</div>
                        </div>
                      </div>
                      <div className='booking-det-total'>
                        <div className='booking-det-total-item '>
                          <div className='item-head'>
                            Pre-Booking Price per cover:
                          </div>
                          <div>
                            {" "}
                            £
                            {Number(
                              prePaymentData?.Rules?.PaymentAmountPerCover
                                ?.Amount / 100
                            ).toFixed(2) || Number(0).toFixed(2)}
                          </div>
                        </div>
                        <div className='booking-det-total-item '>
                          <div className='item-head'>Number of Covers:</div>
                          <div>{guest}</div>
                        </div>{" "}
                        <div className='booking-det-total-item  booking-det-total-item-head'>
                          <div className='item-head'>Pre-Booking Subtotal:</div>
                          <div>
                            £
                            {Number(
                              (prePaymentData?.Rules?.PaymentAmountPerCover
                                ?.Amount *
                                guest) /
                                100
                            ).toFixed(2) || Number(0).toFixed(2)}
                          </div>
                        </div>{" "}
                        {payment &&
                          payment?.Payment?.ProcessingFee?.IsEnaled && (
                            <div className='booking-det-total-item booking-det-total-item-sub'>
                              <div className='item-head'>
                                Processing Fee (
                                {payment?.Payment?.ProcessingFee?.Percentage ||
                                  0}
                                %):
                              </div>
                              <div>
                                {payment?.Payment?.ProcessingFee?.Amount?.Text}
                              </div>
                            </div>
                          )}
                        <div className='booking-det-total-item booking-det-total-item-head '>
                          <div className='item-head'>Total to Pay:</div>
                          <div>
                            £
                            {Number(
                              payment?.Payment?.Amount?.Amount / 100
                            ).toFixed(2) || Number(0).toFixed(2)}
                          </div>
                        </div>
                      </div>

                      <div className='back-btn-cont'>
                        <Button
                          text='Back'
                          onClick={() => {
                            dispatch(setPrePaymentData(null));
                            navigate("/booking/customer-details");
                          }}
                          borderAuth
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ErrorModal
        isOpen={isError}
        onClose={() => {
          handleBookingFailed();
        }}
        isLoading={isBookingErrorLoading}
      />
      {isPaymentError && (
        <PaymentFailed
          onClose={() => {
            localStorage.removeItem("REID");
            setIsPaymentError(false);
            window.location.reload();
          }}
          data={paymentData}
        />
      )}
    </div>
  );
};
export default BookingPayment;
