import axios from "axios";
import globalValues from "../../globalValues";
import { generateToken, generateUserToken } from "../../token/requests/Token";

export const userLogin = async (
  username: string,
  password: string
): Promise<number> => {
  try {
    const token = await generateUserToken();
    if (!token) {
      throw new Error("Failed to generate token");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    const data = { UserName: username, Password: password };
    const url = `${globalValues.baseURLS.REACT_APP_USERSERVICE_URL}/v1/controlcenter/login`;

    const response = await axios.post(url, data, { headers });

    if (!response.data.Status) {
      return 401;
    }

    const userDetails = response.data;
    localStorage.setItem("userDetails", JSON.stringify(userDetails));

    const ids: string[] = userDetails.OrgManagementData.map(
      (item: any) => item.OrganizationData.ID
    );

    const subscriptionDetails = await getSubscriptionDetails(ids);

    if (subscriptionDetails.length > 0) {
      const locationId = subscriptionDetails[0]?.CloudLocationID || "";
      localStorage.setItem("wrlId", locationId);

      const managementData = userDetails.OrgManagementData;
      const organizationNameMap = new Map<string, string>();

      managementData.forEach((org: any) => {
        organizationNameMap.set(
          org.OrganizationData.ID,
          org.OrganizationData.Name
        );
      });

      const locationMap = new Map<string, string>();
      subscriptionDetails.forEach((loc: any) => {
        locationMap.set(loc.CloudLocationID, loc.OrganizationID);
      });

      const matchingLocations = managementData.flatMap((org: any) =>
        org.OrganizationData.Companies.flatMap((company: any) =>
          company.Locations.filter(
            (location: any) =>
              locationMap.has(location.ID) &&
              locationMap.get(location.ID) === org.OrganizationData.ID
          ).map((location: any) => ({
            ...location,
            OrganizationName: organizationNameMap.get(org.OrganizationData.ID),
          }))
        )
      );

      localStorage.setItem("OCLIDs", JSON.stringify(subscriptionDetails));
      localStorage.setItem("locations", JSON.stringify(matchingLocations));

      return 200;
    } else {
      throw new Error("Failed to fetch subscription details");
    }
  } catch (err) {
    console.error("Error in userLogin:", err);
    localStorage.clear();
    return 500;
  }
};

export const getSubscriptionDetails = async (Ids: string[]): Promise<any[]> => {
  try {
    const token = await generateToken();
    if (!token) {
      throw new Error("Failed to generate token");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    const data = { IDs: Ids };
    const url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/witmeg-reservations/location/validate`;

    const response = await axios.post(url, data, { headers });

    if (response.data.Status) {
      return response.data.Result;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching subscription details:", error);
    throw error;
  }
};
