import React, { useEffect } from "react";
import "./ModalStyles.scss";
import img from "../../../assets/warn.svg";

interface PaymentFailedProps {
  onClose: () => void;
  data: any;
}

const PaymentFailed: React.FC<PaymentFailedProps> = ({ onClose, data }) => {
  useEffect(() => {
    if (!data) {
      onClose();
    }
  }, []);
  return (
    <div className='modal-overlay'>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>
          <img alt='' src={img} className='failed' />
          Payment Failed
        </div>
        <div className='content-2'>
          The payment for booking
          <span
            style={{ color: "#4f4e4e", fontWeight: 600, marginLeft: "10px" }}
          >
            {data.name} {data.lastName} on {data.date}, for a party of{" "}
            {data.guest}
          </span>{" "}
          was unsuccessful.
        </div>
        <div className='btn-container'>
          <div className='failed-warning' onClick={onClose}>
            Okay
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
