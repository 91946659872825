import moment from "moment";

const splitTimeRange = (
  startTime: string,
  endTime: string,
  interval: number
): string[] => {
  const start = moment(startTime, "HH:mm");
  const end = moment(endTime, "HH:mm");
  const timeSlots: string[] = [];

  while (start <= end) {
    timeSlots.push(start.format("HH:mm"));
    start.add(interval, "minutes");
  }

  return timeSlots;
};
export const getTimeSlotsWithShifts = (
  date: string,
  selectedParty: number,
  shiftData: any
): {
  shiftName: string;
  timeSlots: string[];
  shiftId: string;
  type: string;
}[] => {
  const timeSlots: {
    shiftName: string;
    timeSlots: string[];
    shiftId: string;
    type: string;
  }[] = [];
  const dayName = moment(date).format("dddd").toUpperCase();
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const formatted = moment(date).format("M/D/YYYY");

  const onetimeSlots: any[] = [];
  const recurringSlots: any[] = [];

  shiftData.forEach((shift: any) => {
    if (shift.Type === "ONETIME") {
      const onetime = [shift.TimeSchedule];
      const isActive = generateUniqueDates(onetime).some((shiftDay: any) => {
        return shiftDay === formatted;
      });
      if (
        isActive &&
        selectedParty >= shift.ShiftAvailability.ReservationCover.Minimum &&
        selectedParty <= shift.ShiftAvailability.ReservationCover.Maximum
      ) {
        const slots = splitTimeRange(
          shift.ActiveTime.First,
          shift.ActiveTime.Last,
          shift.ShiftAvailability.Timeslot
        );
        if (
          timeSlots[timeSlots.length - 1]?.timeSlots[
            timeSlots[timeSlots.length - 1].timeSlots.length - 1
          ] === slots[0]
        ) {
          slots.shift();
        }

        onetimeSlots.push({
          shiftName: shift.Name,
          timeSlots: slots,
          shiftId: shift.ShiftManagementID,
          type: "ONETIME",
        });
      }
    }

    if (shift.IsActive && shift.Type === "RECURRING") {
      const shiftActiveDay = shift.ShiftActiveDay.find((shiftDay: any) => {
        return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
      });
      if (
        shiftActiveDay &&
        selectedParty >= shift.ShiftAvailability.ReservationCover.Minimum &&
        selectedParty <= shift.ShiftAvailability.ReservationCover.Maximum
      ) {
        const slots = splitTimeRange(
          shiftActiveDay.StartTime,
          shiftActiveDay.EndTime,
          shift.ShiftAvailability.Timeslot
        );
        if (
          timeSlots[timeSlots.length - 1]?.timeSlots[
            timeSlots[timeSlots.length - 1].timeSlots.length - 1
          ] === slots[0]
        ) {
          slots.shift();
        }
        recurringSlots.push({
          shiftName: shift.Name,
          timeSlots: slots,
          shiftId: shift.ShiftManagementID,
          type: "RECURRING",
        });
      }
    }
  });
  recurringSlots.forEach((shift) => {
    timeSlots.push({
      shiftName: shift.shiftName,
      timeSlots: shift.timeSlots,
      shiftId: shift.shiftId,
      type: shift.type,
    });
  });
  onetimeSlots.forEach((shift) => {
    timeSlots.push({
      shiftName: shift.shiftName,
      timeSlots: shift.timeSlots,
      shiftId: shift.shiftId,
      type: shift.type,
    });
  });
  const filterRecurringTimeSlots = (shifts: any) => {
    const oneTimeSlots = new Set(
      shifts
        .filter((shift: any) => shift.type === "ONETIME")
        .flatMap((shift: any) => shift.timeSlots)
    );

    shifts.forEach((shift: any) => {
      if (shift.type === "RECURRING") {
        shift.timeSlots = shift.timeSlots.filter(
          (slot: any) => !oneTimeSlots.has(slot)
        );
      }
    });

    return shifts;
  };

  return filterRecurringTimeSlots(timeSlots);
};
export const findShiftByTime = (shifts: any[], time: string): any | null => {
  for (const shift of shifts) {
    if (shift.timeSlots.includes(time)) {
      return shift;
    }
  }
  return null;
};

export const getTimeSlotsForDate = (
  date: string,
  selectedParty: number,
  shiftData: any
): any[] => {
  const timeSlots: any[] = [];
  const dayName = moment(date).format("dddd").toUpperCase();
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const formatted = moment(date).format("M/D/YYYY");
  const onetimeSlots: any[] = [];
  const recurringSlots: any[] = [];
  shiftData.forEach((shift: any) => {
    if (shift.IsActive && shift.Type === "ONETIME") {
      const onetime = [shift.TimeSchedule];
      const isActive = generateUniqueDates(onetime).some((shiftDay: any) => {
        return shiftDay === formatted;
      });
      if (
        isActive &&
        selectedParty >= shift.ShiftAvailability.ReservationCover.Minimum &&
        selectedParty <= shift.ShiftAvailability.ReservationCover.Maximum
      ) {
        const slots = splitTimeRange(
          shift.ActiveTime.First,
          shift.ActiveTime.Last,
          shift.ShiftAvailability.Timeslot
        );
        if (timeSlots[timeSlots.length - 1] === slots[0]) {
          slots.shift();
        }
        onetimeSlots.push(...slots);
      }
    }
    if (shift.IsActive && shift.Type === "RECURRING") {
      const shiftActiveDay = shift.ShiftActiveDay.find((shiftDay: any) => {
        return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
      });
      if (
        shiftActiveDay &&
        selectedParty >= shift.ShiftAvailability.ReservationCover.Minimum &&
        selectedParty <= shift.ShiftAvailability.ReservationCover.Maximum
      ) {
        const slots = splitTimeRange(
          shiftActiveDay.StartTime,
          shiftActiveDay.EndTime,
          shift.ShiftAvailability.Timeslot
        );
        if (timeSlots[timeSlots.length - 1] === slots[0]) {
          slots.shift();
        }
        recurringSlots.push(...slots);
      }
    }
  });
  if (onetimeSlots.length > 0) {
    const removeAfterTime = (
      firstArray: string[],
      secondArray: string[]
    ): string[] => {
      const firstStartTime = firstArray[0];
      const firstEndTime = firstArray[firstArray.length - 1];

      const filteredArray = secondArray.filter(
        (time) => time <= firstStartTime
      );

      const afterEndArray = secondArray.filter((time) => time > firstEndTime);

      const combinedArray = [...filteredArray, ...firstArray].sort((a, b) => {
        const [aHour, aMinute] = a.split(":").map(Number);
        const [bHour, bMinute] = b.split(":").map(Number);
        return aHour === bHour ? aMinute - bMinute : aHour - bHour;
      });
      return [...combinedArray, ...afterEndArray];
    };

    const result = removeAfterTime(onetimeSlots, recurringSlots);
    timeSlots.push(...result);
  } else {
    timeSlots.push(...recurringSlots);
  }
  return timeSlots;
};
export const getAllTimeSlotsForDate = (date: string, shiftData: any): any[] => {
  const timeSlots: any[] = [];
  const dayName = moment(date).format("dddd").toUpperCase();
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const formatted = moment(date).format("M/D/YYYY");

  const onetimeSlots: any[] = [];
  const recurringSlots: any[] = [];
  shiftData.forEach((shift: any) => {
    if (shift.IsActive && shift.Type === "ONETIME") {
      const onetime = [shift.TimeSchedule];
      const isActive = generateUniqueDates(onetime).some((shiftDay: any) => {
        return shiftDay === formatted;
      });
      if (isActive) {
        const slots = splitTimeRange(
          shift.ActiveTime.First,
          shift.ActiveTime.Last,
          shift.ShiftAvailability.Timeslot
        );
        if (timeSlots[timeSlots.length - 1] === slots[0]) {
          slots.shift();
        }
        onetimeSlots.push(...slots);
      }
    }
    if (shift.IsActive && shift.Type === "RECURRING") {
      const shiftActiveDay = shift.ShiftActiveDay.find((shiftDay: any) => {
        return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
      });
      if (shiftActiveDay) {
        const slots = splitTimeRange(
          shiftActiveDay.StartTime,
          shiftActiveDay.EndTime,
          shift.ShiftAvailability.Timeslot
        );
        if (timeSlots[timeSlots.length - 1] === slots[0]) {
          slots.shift();
        }
        recurringSlots.push(...slots);
      }
    }
  });
  if (onetimeSlots.length > 0) {
    const removeAfterTime = (
      firstArray: string[],
      secondArray: string[]
    ): string[] => {
      const firstStartTime = firstArray[0];
      const firstEndTime = firstArray[firstArray.length - 1];

      // Filter times in the secondArray that are before or equal to the start time of the firstArray
      const filteredArray = secondArray.filter(
        (time) => time <= firstStartTime
      );

      // Filter times that are after the end time of the firstArray
      const afterEndArray = secondArray.filter((time) => time > firstEndTime);

      // Combine filteredArray and firstArray, then sort
      const combinedArray = [...filteredArray, ...firstArray].sort((a, b) => {
        const [aHour, aMinute] = a.split(":").map(Number);
        const [bHour, bMinute] = b.split(":").map(Number);
        return aHour === bHour ? aMinute - bMinute : aHour - bHour;
      });

      // Add afterEndArray to the end of the combinedArray
      return [...combinedArray, ...afterEndArray];
    };

    const result = removeAfterTime(onetimeSlots, recurringSlots);
    timeSlots.push(...result);
  } else {
    timeSlots.push(...recurringSlots);
  }
  return timeSlots;
};
export const getReservationCovers = (
  selectedDate: string,
  shiftData: any
): number[] => {
  const filteredShifts = shiftData.filter((shift: any) => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const dayName = moment(selectedDate).format("dddd").toUpperCase();
    const formatted = moment(selectedDate).format("M/D/YYYY");
    if (shift.Type === "RECURRING" && shift.IsActive) {
      return shift.ShiftActiveDay.some((shiftDay: any) => {
        return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
      });
    }
    if (shift.Type === "ONETIME" && shift.IsActive) {
      const onetime = [shift.TimeSchedule];
      return generateUniqueDates(onetime).some((shiftDay: any) => {
        return shiftDay === formatted;
      });
    }
  });
  if (filteredShifts.length === 0) {
    return [];
  }

  const allMinimums = filteredShifts.map(
    (shift: any) => shift.ShiftAvailability.ReservationCover.Minimum
  );
  const allMaximums = filteredShifts.map(
    (shift: any) => shift.ShiftAvailability.ReservationCover.Maximum
  );

  const min = Math.min(...allMinimums);
  const max = Math.max(...allMaximums);

  const numberArray: number[] = [];
  for (let i = min; i <= max; i++) {
    numberArray.push(i);
  }

  return numberArray;
};
export const convertTime = (isoString: string) => {
  const date = new Date(isoString);
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};
export const getLocations = (data: any) => {
  let resultArray = data.reduce((acc: any, location: any) => {
    const orgName = location.OrganizationName;
    if (!acc[orgName]) {
      acc[orgName] = [];
    }
    acc[orgName].push(location);
    return acc;
  }, {});
  return resultArray;
};
export const convertDate = (isoString: string) => {
  const date = new Date(isoString);
  return date;
};
export const generateUniqueDates = (dateRanges: any[]) => {
  const dateSet = new Set();
  const result: any[] = [];

  dateRanges.forEach(({ StartDate, EndDate }) => {
    let currentDate = new Date(StartDate);
    const endDate = new Date(EndDate);
    let currentDateUTC = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds()
    );
    let endDateUTC = new Date(
      endDate.getUTCFullYear(),
      endDate.getUTCMonth(),
      endDate.getUTCDate(),
      endDate.getUTCHours(),
      endDate.getUTCMinutes(),
      endDate.getUTCSeconds()
    );
    while (currentDateUTC <= endDateUTC) {
      const dateString = moment(currentDateUTC).format("M/D/YYYY");
      if (!dateSet.has(dateString)) {
        dateSet.add(dateString);
        result.push(dateString);
      }
      currentDateUTC.setDate(currentDateUTC.getDate() + 1);
    }
  });
  return result;
};
