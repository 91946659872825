import React from "react";
import "./ModalStyles.scss";
import img from "../../../assets/warn.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../application/store";

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpen,
  onClose,
  isLoading,
}) => {
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>
          <img alt='' src={img} className='failed' />
          Booking Failed
        </div>

        <div className='content'>
          The booking failed due to unavailability of the selected time slot.
          Please contact the restaurant [
          {organizationDetails?.ContactDetails?.PhoneCode}{" "}
          {organizationDetails?.ContactDetails?.Number}] for further
          clarification.
        </div>
        <div className='btn-container'>
          <div className='failed-warning' onClick={onClose}>
            {isLoading && <div className='loading-ic-wran' />}
            Okay
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
