import axios from "axios";
import globalValues from "../../globalValues";
import { generateToken } from "../../token/requests/Token";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const getOrganizationDetails = async (locationID: string) => {
  try {
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/witmeg-reservations/location/${locationID}/find`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then((response) => {
              if (response.data.Status) {
                const organizationDetails = response.data.Result;
                localStorage.setItem(
                  "org",
                  JSON.stringify(organizationDetails)
                );
                resolve(organizationDetails);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Get Organization Data", error);
  }
};
export const getShiftDetails = async (locationID: string) => {
  try {
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/shift/location/${locationID}/findall`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then((response) => {
              if (response.data.Status) {
                const shiftDetails = response.data.Result;
                localStorage.setItem("shifts", JSON.stringify(shiftDetails));
                resolve(shiftDetails);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Shift Data", error);
  }
};
export const makeReservation = async (booking: any) => {
  try {
    let orgDetails = JSON.parse(localStorage.getItem("org") || "{}");
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      let reserveObj = {
        BookingID: booking.ID ? booking.ID : "",
        ReservationID: "",
        OrganizationID: orgDetails.OrganizationDetails.ID,
        CloudLocationID: locationID,
        CustomerDetails: {
          Id: "",
          FirstName: booking.firstName,
          LastName: booking.lastName,
          MiddleName: "",
          Email: booking.email,
          ContactDetails: [
            {
              PhoneCode: "+44",
              Number: booking.phoneNumber,
              CountryCode: "GB",
            },
          ],
        },
        BookingDetails: {
          BookingDate: moment(booking.date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
          BookingTime: booking.time,
          UserPreferences: {
            PreferenceType: "Seating",
            PreferenceDetail: "Window seat",
          },
          Duration: booking.duration,
          NumberOfPeople: booking.guest,
        },
        BookingSource: "ONLINE",
        ReservationNotes: booking.request,
        PaymentDetails: booking.PaymentDetails ?? {},
      };
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/bookings/add`;
          await axios({
            method: "POST",
            url: url,
            headers,
            data: reserveObj,
          })
            .then((response) => {
              if (response.data.Status) {
                resolve(response.data.Result);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Shift Data", error);
  }
};
export const getSpotAvailability = async (locationID: string, date: string) => {
  try {
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/spot/get?location_id=${locationID}&date=${date}`;
          await axios({
            method: "GET",
            url: url,
            headers,
            timeout: 10000,
          })
            .then((response) => {
              resolve(response.data.Result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Spot Data", error);
  }
};
export const getBookingDetails = async (date: string) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let data = {
            CloudLocationID: locationID,
            Date: moment(date).format("YYYY-MM-DD"),
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/booking/list`;
          await axios({
            method: "POST",
            url: url,
            headers,
            data,
          })
            .then((response) => {
              resolve(response.data.Result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Get Booking Details", error);
  }
};
export const getFloorDetails = async (date: string) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/location/${locationID}/floor-management?date=${date}`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then((response) => {
              if (response.data.Result !== null) {
                resolve(response.data.Result);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Spot Data", error);
  }
};
export const getStatus = async () => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/status/find`;
        await axios({
          method: "GET",
          url: url,
          headers,
        })
          .then((response) => {
            localStorage.setItem(
              "status",
              JSON.stringify(response.data.Result)
            );
            resolve(response.data.Result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In Spot Data", error);
  }
};
export const getTags = async () => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/tag/find`;
        await axios({
          method: "GET",
          url: url,
          headers,
        })
          .then((response) => {
            localStorage.setItem("tags", JSON.stringify(response.data.Result));
            resolve(response.data.Result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In Spot Data", error);
  }
};
export const makeReservationInhouse = async (booking: any) => {
  try {
    let orgDetails = localStorage.getItem("org")
      ? JSON.parse(localStorage.getItem("org") || "{}")
      : "";
    const locationID = localStorage.getItem("wrlId");
    if (orgDetails !== "") {
      if (locationID !== "") {
        const token = await generateToken();
        let obj = {
          OrganizationID: orgDetails?.OrganizationDetails?.ID || "",
          CloudLocationID: locationID,
          CustomerDetails: {
            Id: "",
            FirstName: booking.firstName,
            LastName: booking.lastName,
            MiddleName: "",
            Email: booking.email,
            ContactDetails: [
              {
                PhoneCode: "+44",
                Number: booking.phoneNumber,
                CountryCode: "GB",
              },
            ],
          },
          BookingDetails: {
            BookingDate: moment(booking.date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
            BookingTime: booking.time,
            UserPreferences: {
              PreferenceType: "Seating",
              PreferenceDetail: "Window seat",
            },
            Duration: booking.duration,
            NumberOfPeople: booking.guest,
          },
          AssetAllocation: booking.assets,
          ReservationRequestDate: moment(new Date()).format(
            "YYYY-MM-DDTHH:mm:ss[Z]"
          ),
          Status: booking.status,
          BookingSource: "IN_HOUSE",
          ConfirmationNumber: uuidv4().slice(-8),
          ReservationNotes: booking.request,
          WaitList: {
            Enabled: booking.waitList,
            WaitTime: booking.waitTime,
          },
          WalkIn: {
            Enabled: booking.walkIn,
            SendConfirmationMessage: false,
          },
          StatusInfo: booking.statusInfo,
          BookingTag: booking.tags,
        };
        if (token) {
          return new Promise(async (resolve, reject) => {
            let headers = {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            };
            let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v3/bookings/add`;
            await axios({
              method: "POST",
              url: url,
              headers,
              data: obj,
            })
              .then((response) => {
                if (response.data.Status) {
                  resolve(response.data.Result);
                } else {
                  resolve(false);
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error In make Reservation In house", error);
  }
};
export const updateReservation = async (booking: any) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/booking/update`;
          await axios({
            method: "PUT",
            url: url,
            headers,
            data: booking,
          })
            .then((response) => {
              if (response.data.Status) {
                resolve(response.data.Result);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        return false;
      }
    }
  } catch (error) {
    console.error("Error In Shift Data", error);
  }
};
export const getFloorManagementDetails = async () => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "" && locationID) {
      let date = moment().format("YYYY-MM-DD");
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/location/${locationID}/section?date=${date}`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then((response) => {
              if (response.data.Result !== null) {
                resolve(response.data.Result);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        return false;
      }
    }
  } catch (error) {
    console.error("Error In getFloorManagementDetails Data", error);
  }
};
export const getBookingByID = async (id: string) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/booking/${id}/find`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then((response) => {
              resolve(response.data.Result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Get Booking Data", error);
  }
};
export const blockReservations = async (data: any) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/blocked-hours`;
          await axios({
            method: "POST",
            url: url,
            headers,
            data,
          })
            .then((response) => {
              resolve(response.data.Result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Get Booking Details", error);
  }
};
export const getBlockedReservations = async (
  locationID: string,
  date: string
) => {
  try {
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/blocked-hours/${locationID}?Date=${date}`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then((response) => {
              if (response.data.Result !== null) {
                resolve(response.data.Result);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Get Blocked Reservation", error);
  }
};
export const deleteBlockedReservations = async (resId: string) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/blocked-hours/${resId}/remove`;
        await axios({
          method: "DELETE",
          url: url,
          headers,
        })
          .then((response) => {
            if (response.data.Result !== null) {
              resolve(response.data.Result);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In delete floor", error);
  }
};
export const getPrePaymentDetails = async (id: string) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/pre-payment/shift/${id}`;
          await axios({
            method: "GET",
            url: url,
            headers,
          })
            .then((response) => {
              if (response.data.Result) {
                resolve(response.data.Result);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Get Booking Data", error);
  }
};
export const fethPaymentDetails = async (data: any) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/prepayment/find`;
          await axios({
            method: "POST",
            url: url,
            headers,
            data,
          })
            .then((response) => {
              if (response.data.Result) {
                resolve(response.data.Result);
              } else {
                resolve(null);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Fetching Payment Data", error);
  }
};
export const passPaymentMethodToDropin = async (
  requestBody = {},
  token: string
) => {
  try {
    const { ...body } = requestBody;
    return new Promise(async (resolve, reject) => {
      const apiReqUrl = `${globalValues.baseURLS.REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/payment/instant`;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      };

      await axios({
        method: "POST",
        url: apiReqUrl,
        data: body,
        headers,
      })
        .then((response) => {
          const { Status, Result } = response.data;
          resolve(Result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } catch (error) {
    console.error("Error in passing payment to drop in", error);
  }
};
export const sendPaymentDetails = async (requestBody = {}, token: string) => {
  try {
    return new Promise(async (resolve, reject) => {
      const apiReqUrl = `${globalValues.baseURLS.REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/pay/paymentdetail`;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      };

      await axios({
        method: "POST",
        url: apiReqUrl,
        data: requestBody,
        headers,
      })
        .then((response) => {
          const { Status, Result } = response.data;
          let resolveValue = Status ? Result : null;
          resolve(resolveValue);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } catch (error) {
    console.error("Error in sending payment details", error);
  }
};
export const storeTempBooking = async (booking: any) => {
  try {
    let orgDetails = JSON.parse(localStorage.getItem("org") || "{}");
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();
      let reserveObj = {
        BookingID: booking.ID ? booking.ID : "",
        ReservationID: "",
        OrganizationID: orgDetails.OrganizationDetails.ID,
        CloudLocationID: locationID,
        CustomerDetails: {
          Id: "",
          FirstName: booking.firstName,
          LastName: booking.lastName,
          MiddleName: "",
          Email: booking.email,
          ContactDetails: [
            {
              PhoneCode: "+44",
              Number: booking.phoneNumber,
              CountryCode: "GB",
            },
          ],
        },
        BookingDetails: {
          BookingDate: moment(booking.date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
          BookingTime: booking.time,
          UserPreferences: {
            PreferenceType: "Seating",
            PreferenceDetail: "Window seat",
          },
          Duration: booking.duration,
          NumberOfPeople: booking.guest,
        },
        BookingSource: "ONLINE",
        ReservationNotes: booking.request,
      };
      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/booking/temp-dump`;
          await axios({
            method: "POST",
            url: url,
            headers,
            data: reserveObj,
          })
            .then((response) => {
              if (response.data.Status) {
                resolve(response.data.Result);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Shift Data", error);
  }
};
export const updateTempBooking = async (id: string) => {
  try {
    const locationID = localStorage.getItem("wrlId");
    if (locationID !== "") {
      const token = await generateToken();

      if (token) {
        return new Promise(async (resolve, reject) => {
          let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          };
          let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/booking-dump/${id}/update`;
          await axios({
            method: "PUT",
            url: url,
            headers,
          })
            .then((response) => {
              if (response.data.Status) {
                resolve(response.data.Result);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  } catch (error) {
    console.error("Error In Shift Data", error);
  }
};
